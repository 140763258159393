import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import StoresPage from "../components/StoresPage"

const StoresArchivePage = ({ data, location }) => {

    const localeID = 'sk_SK';

    return (
        <Layout location={location} localeID={localeID} navShadow={true}>
            <Seo title="Prevádzky" />
            <StoresPage localeID={localeID} data={data}/>
        </Layout>
    )
  }
  
export default StoresArchivePage

export const pageQuery = graphql`
    query StoresArchivePageSK {
        allStores: allWpPost(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: "prevadzky"}}}}, locale: {locale: {eq: "sk_SK"}}}
        ) {
            ...StoreSliderData
        }
        cuisineWorld: allWpPost(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: "svetove-kuchyne"}}}}, locale: {locale: {eq: "sk_SK"}}}
        ) {
            ...StoreSliderData
        }
        vegetarian: allWpPost(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: "vegetarianske"}}}}, locale: {locale: {eq: "sk_SK"}}}
        ) {
            ...StoreSliderData
        }
        farmers: allWpPost(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: "farmarska-trznica"}}}}, locale: {locale: {eq: "sk_SK"}}}
        ) {
            ...StoreSliderData
        }
        vegetable: allWpPost(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: "ovocie-zelenina"}}}}, locale: {locale: {eq: "sk_SK"}}}
        ) {
            ...StoreSliderData
        }
        bakery: allWpPost(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: "pekaren-cukraren"}}}}, locale: {locale: {eq: "sk_SK"}}}
        ) {
            ...StoreSliderData
        }
        foreign: allWpPost(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: "zahranicne-speciality"}}}}, locale: {locale: {eq: "sk_SK"}}}
        ) {
            ...StoreSliderData
        }
        ekoGro: allWpPost(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: "ekologicke-potraviny"}}}}, locale: {locale: {eq: "sk_SK"}}}
        ) {
            ...StoreSliderData
        }
        books: allWpPost(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: "kvety-knizky"}}}}, locale: {locale: {eq: "sk_SK"}}}
        ) {
            ...StoreSliderData
        }
        alcohol: allWpPost(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: "alkoholicke-speciality"}}}}, locale: {locale: {eq: "sk_SK"}}}
        ) {
            ...StoreSliderData
        }
        meatFish: allWpPost(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: "maso-ryby"}}}}, locale: {locale: {eq: "sk_SK"}}}
        ) {
            ...StoreSliderData
        }
        cheese: allWpPost(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: "syrove-speciality"}}}}, locale: {locale: {eq: "sk_SK"}}}
        ) {
            ...StoreSliderData
        }
        bars: allWpPost(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: "kaviarne-bary"}}}}, locale: {locale: {eq: "sk_SK"}}}
        ) {
            ...StoreSliderData
        }
        tags: allWpTag(filter: {slug: {regex: "/^sk/"}}) {
            nodes {
                name
                description
                slug
            }
        }
    }
`